import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { chartLayersActions, surveyActions } from '../../redux/actions';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';
import { Data, Questionnaire, Reports, Tables } from '..';
import { SurveySettings } from './SurveySettings';

export const Survey = ({ match }) => {
  const { path, params } = match;
  const { surveyid } = params;
  const survey = useSelector(state => state.survey);

  useEffect(() => {
    if (surveyid && (surveyid !== survey.id || !survey.questionnaire?.id)) {
      if (!survey.loading && !survey.error) {
        surveyActions.getSurvey(surveyid, {
          questionnaire: '1',
          translations: '1',
          data: '1',
          tablesFolders: '1',
          tables: '1',
          reports: '1'
        });
        chartLayersActions.loadChartLayers(surveyid);
      }
    }
  }, [surveyid, survey.id, survey.loading, survey.questionnaire.id, survey.error]);

  if (survey.error)
    return (
      <div className='container-fluid base-content flex-fill'>
        {parseErrorAsMessageString(
          survey.error,
          'It seems like you do not have access to this page. Please refresh the page and try selecing a different project.'
        )}
      </div>
    );

  return (
    <DndProvider backend={HTML5Backend}>
      <Switch>
        <Route path={`${path}/survey`} component={SurveySettings} />
        <Route path={`${path}/que`} component={Questionnaire} />
        <Route path={`${path}/data`} component={Data} />
        <Route path={`${path}/tables/:tableid?`} component={Tables} />
        <Route path={`${path}/reports/:reportid?/pages/:pageid?`} component={Reports} />
        <Route path={`${path}/reports/:reportid?`} component={Reports} />
      </Switch>
    </DndProvider>
  );
};

// const Loading = () => (
//   <div style={{ height: '90vh' }} className='d-flex justify-content-center align-items-center'>
//     <span className='fas fa-spinner fa-spin fa-3x' />
//   </div>
// );
