import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';

import './SmvExportFileButtonModal.scss';

export const ExportFileButton = ({
  toggle,
  type,
  exportHandler = null,
  exportOptions = null,
  handlerRef,
  iconOnly = false,
  renderAsButton = false,
  className = ''
}) => {
  const _toggle = () => {
    if (exportHandler || exportOptions) {
      handlerRef.current = {
        exportHandler: exportHandler,
        exportOptions: exportOptions
      };
    }
    toggle();
  };

  if (renderAsButton) {
    return (
      <Button
        id={`${iconOnly ? `tt_export-${type}` : ''}`}
        onClick={_toggle}
        className={className}
        size={'sm'}
        color={'secondary'}
        outline={true}
      >
        <i className={`${iconOnly ? 'fal' : 'fal mr-1'} fa-file-export`} />
        {!iconOnly && (
          <>
            <span>
              <FormattedMessage defaultMessage={`export ${type}`} id={`smoove.page.reports.export-${type}`} />
            </span>
          </>
        )}
        {iconOnly && (
          <UncontrolledTooltip target={`tt_export-${type}`} delay={{ hide: 200 }} autohide={false}>
            Export {type}
          </UncontrolledTooltip>
        )}
      </Button>
    );
  }

  return (
    <div className={`smv-custom-dropdown__buttons ${className}`} onClick={_toggle}>
      <i className={`${iconOnly ? 'far' : 'fal mr-1'} fa-file-export icon-smv-blue `} />
      {!iconOnly && (
        <span>
          <FormattedMessage defaultMessage={`export ${type}`} id={`smoove.page.reports.export-${type}`} />
        </span>
      )}
    </div>
  );
};

export const ExportFileModal = ({
  isOpen,
  toggle,
  type,
  exportHandler = null,
  exportOptions = null,
  handlerRef = null
}) => {
  const intl = useIntl();

  const _exportOptions = useMemo(
    () => exportOptions ?? handlerRef?.current?.exportOptions ?? { pptx: true, xlsx: true },
    [exportOptions, handlerRef]
  );

  const [exportFileType, setExportFileType] = useState(Object.keys(_exportOptions)?.[0]);

  const saveHandler = useCallback(() => {
    if (exportHandler) exportHandler(exportFileType);
    else if (handlerRef?.current?.exportHandler) handlerRef.current.exportHandler(exportFileType);
    toggle();
  }, [exportHandler, handlerRef, exportFileType, toggle]);

  return (
    <Modal className='smv-export-file-modal' isOpen={isOpen} toggle={toggle} container={'body'}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage defaultMessage={`Export ${type}`} id={`smoove.page.reports.export-${type}`} />
      </ModalHeader>
      <ModalBody>
        <>
          <h5 className='mt-2'>{intl.formatMessage({ id: 'smoove.page.reports.select-file-type' })}</h5>
          <div className='export-list'>
            {Object.keys(_exportOptions).map(_exportType => {
              if (!_exportOptions[_exportType]) return null;
              return (
                <div
                  key={`export-card-${_exportType}`}
                  className={classNames({
                    'export-list__item': true,
                    'export-list__item--active': exportFileType === _exportType
                  })}
                  onClick={() => setExportFileType(_exportType)}
                >
                  <div className={'export-list__item-icon'}>
                    <i
                      className={classNames({
                        'fas fa-2x': true,
                        'fa-file-powerpoint': _exportType === 'pptx',
                        'fa-file-image': _exportType === 'image',
                        'fa-file-pdf': _exportType === 'pdf',
                        'fa-file-spreadsheet': _exportType === 'xlsx'
                      })}
                    />
                  </div>
                  <div className={'export-list__item-label'}>
                    <FormattedMessage
                      id={`smoove.page.reports.export.modal.cards.export-as-${_exportType}`}
                      defaultMessage={`Export as ${_exportType}`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
        <Button color='primary' onClick={saveHandler}>
          {intl.formatMessage({ id: 'smoove.page.reports.export-btn' })}
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button color={'grey'} onClick={toggle}>
          <FormattedMessage defaultMessage={`Cancel`} id={`smoove.common.buttons.cancel`} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const ExportFileButtonAndModal = ({
  type,
  exportHandler = null,
  exportOptions = null,
  renderAsButton = false,
  iconOnly = false,
  buttonClassName = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(b => !b);

  return (
    <>
      <ExportFileButton
        toggle={toggle}
        type={type}
        renderAsButton={renderAsButton}
        iconOnly={iconOnly}
        className={buttonClassName}
      />
      {isOpen && (
        <ExportFileModal
          toggle={toggle}
          isOpen={isOpen}
          type={type}
          exportHandler={exportHandler}
          exportOptions={exportOptions}
        />
      )}
    </>
  );
};
